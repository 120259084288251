import {
  CLOSE_ALERT,

  OPEN_ALERT
} from "./actionTypes";

const openAlert = (payload) => {
  return {
    type: OPEN_ALERT,
    payload: payload,
  };
};

const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
  };
};

export default {
  openAlert,
  closeAlert,
};
