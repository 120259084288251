import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Copyright from "../Copyright";
import firebase from "../../util/firebase";
import { config } from "../../util/config";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 70,
    height: 70,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgetPassword() {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    isLoaded: false,
    email: "",
  });

  const handleUpdate = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleForgetPassWord = (e) => {
    e.preventDefault();
    if (config.isDemo) {
      window.alertMsg("you can't update this in demo mode", "error");
      return;
    }
    var auth = firebase.auth();

// var emailAddress = "user@example.com";

auth.sendPasswordResetEmail(state.email).then(function() {
  // Email sent.
  window.alertMsg("Email sent for resetting password")
  console.log("email sent");
  history.push("/");
}).catch(function(error) {
  console.log(error);
  // An error happened.
});
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
                <img className={classes.avatar} src="/image/svg/logo.svg" alt="logo" />
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} onSubmit={handleForgetPassWord}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={state.email}
            onChange={handleUpdate}
            autoComplete="email"
            autoFocus
          />
          <Button
            startIcon={<NavigateNextOutlinedIcon />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Proceed
          </Button>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Link variant="body2" to="/signup">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
