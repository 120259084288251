import { CLOSE_ALERT, OPEN_ALERT } from "../actions/actionTypes";

const initialState = {
  open: false,
  msg: "alert",
  severity: "success",
  vertical: "top",
  horizontal: "center",
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ALERT:
      return {
        ...state,
        open: true,
        msg: action.payload.msg,
        severity: action.payload.severity,
      };
    case CLOSE_ALERT:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default alert;
