import React from "react";
import "./loader.css";

export default function Loading(props) {
  return (
    <div className="col-sm-2">
      <div className="sp sp-wave"></div>
      <h5>Loading {props.value}</h5>
    </div>
  );
}
