import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/actions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertMessage(props) {
  const alert = useSelector(state => state.alert)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(actions.alert.closeAlert())
  };

  window.alertMsg = (msg, severity="success")=>dispatch(actions.alert.openAlert({msg:msg, severity:severity}))

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={`${alert.vertical},${alert.horizontal}`}
        open={alert.open}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.msg}
        </Alert>
      </Snackbar>
    </Container>
  );
}
