import {
  Collapse,
  Divider,
  List,
  ListSubheader,
  useTheme,
  withStyles,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  BarChart,
  ContactMail,
  ListAlt,
  People,
  Settings,
} from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { handleLogout } from "../../util/functions";

const url = "/dashboard-admin";

export default function MainListItems() {
  const [selectedState, setSelectedState] = useState("");
  const theme = useTheme();
  const mainMenu = [
    {
      title: "Home",
      url: "/dashboard-admin/",
      icon: HomeIcon,
      iconColor: theme.palette.primary.main,
    },
  ];

  const secondaryMenu = [
    {
      title: "Update Profile",
      url: `${url}/update-profile`,
      icon: PersonIcon,
      iconColor: theme.palette.primary.main,
    },
    {
      title: "Change Password",
      url: `${url}/change-password`,
      icon: LockIcon,
      iconColor: theme.palette.warning.main,
    },
  ];
  const StyledListItem = withStyles({
    root: {
      backgroundColor: "#ffffff",
      "&$selected": {
        backgroundColor: theme.palette.primary.lighter,
        "&:hover": {
          backgroundColor: theme.palette.primary.lighter,
        },
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
    selected: {},
  })(ListItem);
  return (
    <div>
      <List component="nav" disablePadding>
        {mainMenu.map((item, index) => (
          <AppMenuItem
            {...item}
            key={index}
            selectedTitle={selectedState}
            onClick={(title) => setSelectedState(title)}
          />
        ))}
        <Divider />
        <ListSubheader inset>Account Details</ListSubheader>
        {secondaryMenu.map((item, index) => (
          <AppMenuItem
            {...item}
            key={index}
            selectedTitle={selectedState}
            onClick={(title) => setSelectedState(title)}
          />
        ))}
        <StyledListItem
          id="6"
          // selected={state.currentSelectedListIndex === "13" ? true : false}
          title="Log Out"
          button
          onClick={handleLogout}
        >
          <ListItemIcon>
            <ExitToAppIcon style={{ color: theme.palette.info.main }} />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </StyledListItem>
      </List>
    </div>
  );
}

const AppMenuItem = (props) => {
  const [selectedState, setSelectedState] = useState("");
  // const { name, url, Icon, items = [] } = props
  const isExpandable = props.subMenu && props.subMenu.length > 0;
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const StyledListItem = withStyles({
    root: {
      backgroundColor: "#ffffff",
      "&$selected": {
        backgroundColor: theme.palette.primary.lighter,
        "&:hover": {
          backgroundColor: theme.palette.primary.lighter,
        },
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
    selected: {},
  })(ListItem);

  function handleClick() {
    setOpen(!open);
    props.onClick(props.title);
  }

  const MenuItemRoot = (
    <StyledListItem
      // id={index}
      selected={props.selectedTitle === props.title ? true : false}
      title={props.title}
      button
      onClick={handleClick}
      component={Link}
      to={props.url}
    >
      {!!props.icon && (
        <ListItemIcon>
          <props.icon style={{ color: props.iconColor }} />
        </ListItemIcon>
      )}
      <ListItemText primary={props.title} inset={!props.icon} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </StyledListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {props.subMenu.map((item, index) => (
          <AppMenuItem
            {...item}
            key={index}
            selectedTitle={props.selectedTitle}
            onClick={(title) => props.onClick(title)}
          />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};
