import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Copyright from "../Copyright";
import firebase from "../../util/firebase";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
import { config } from "../../util/config";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function UpdateProfile() {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch()

  const [form, setState] = useState({
    isLoaded: false,
    payload: {},
  });

  const handleUpdate = (event) => {
    setState({
      ...form,
        [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (config.isDemo) {
      window.alertMsg("you can't update this in demo mode", "error")
      return
      }

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        user.updateProfile({
          displayName: form.full_name,
        }).then(function() {
          // Update successful.
          localStorage.setItem("user", JSON.stringify(user));
          window.alertMsg("Profile Updated")
        }).catch(function(error) {
          // An error happened.
          window.alertMsg("Failed", "error")
        });

        user.updateEmail(form.email).then(function() {
          // Update successful.
          localStorage.setItem("user", JSON.stringify(user));
          //window.alertMsg("Profile Updated")
        }).catch(function(error) {
          // An error happened.
          window.alertMsg("Failed", "error")
        });
      } else {
        // No user is signed in.
        window.alertMsg("Not Signed in", "error")
      }
      
    });

  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        setState({
          ...form,
          full_name: user.displayName,
          email: user.email,
          mobile: user.phoneNumber
        })
      } else {
        // No user is signed in.
      }
    });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="full_name"
                variant="outlined"
                value={form.full_name}
                required
                fullWidth
                id="firstName"
                label="Full Name"
                autoFocus
                onChange={handleUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                name="email"
                variant="outlined"
                value={form.email}
                required
                fullWidth
                id="email"
                label="Email"
                autoFocus
                onChange={handleUpdate}
              />
            </Grid>
          </Grid>
          <Button
            startIcon={<AccountBoxOutlinedIcon />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update Profile
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
