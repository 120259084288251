import firebase from "./firebase";

export const db = firebase.firestore();
const storageRef = firebase.storage().ref();

class CDataService {
  getUid() {
    try {
      return localStorage.getItem("uid");
    } catch (error) {
      return undefined;
    }
  }

  async getDataByCondition(collection_val, condition = {}, callback) {
    let uid = this.getUid();
    let resultValue = {};
    await db
      .collection(collection_val)
      .where("uid", "==", uid)
      .where(condition.name, condition.operator, condition.value)
      .get()
      .then((result) => callback(result));
  }

  async addData(collection_val, payload, callback, documentId = undefined) {
    let uid = this.getUid();
    return await db
      .collection(collection_val)
      .add({
        ...payload,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
      })
      .then(function (result) {
        callback(true, "Data Add Success", result);
        return result;
      })
      .catch(function (error) {
        callback(false, "Data Add Failed", error);
        // throw new Error(error);
      });
  }

  async updateData(collection_val, payload, documentId, callback) {
    let uid = this.getUid();
    return await db
      .collection(collection_val)
      .doc(documentId)
      .update({
        ...payload,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
      })
      .then(async function (result) {
        callback(true, "Data Add Success");
        return result;
      })
      .catch(async function (error) {
        callback(false, "Data Add Failed");
        // throw new Error(error);
      });
  }

  async deleteData(collection_val, documentId, callback) {
    let uid = this.getUid();
    return await db
      .collection(collection_val)
      .doc(documentId)
      .delete()
      .then(function (result) {
        callback(true, "Data Add Success");
        return result;
      })
      .catch(function (error) {
        callback(false, "Data Add Failed");
        // throw new Error(error);
      });
  }

  setSnapshot(collection_val, callback) {
    let uid = this.getUid();
    return db
      .collection(collection_val)
      .where("uid", "==", uid)
      .onSnapshot(function (querySnapshot) {
        callback(querySnapshot);
      });
  }

  setSnapshotOpenSecurity(collection_val, callback) {
    return db.collection(collection_val).onSnapshot(function (querySnapshot) {
      callback(querySnapshot);
    });
  }

  setSnapshotByCondition(collection_val, condition = {}, callback) {
    let uid = this.getUid();
    return db
      .collection(collection_val)
      .where("uid", "==", uid)
      .where(condition.name, condition.operator, condition.value)
      .onSnapshot(function (querySnapshot) {
        callback(querySnapshot);
      });
  }

  setSnapshotByDateRange(
    collection_val,
    startTime = {},
    endTime = {},
    callback
  ) {
    let uid = this.getUid();
    return db
      .collection(collection_val)
      .where("uid", "==", uid)
      .where(startTime.name, startTime.operator, startTime.value)
      .where(endTime.name, endTime.operator, endTime.value)
      .onSnapshot(function (querySnapshot) {
        callback(querySnapshot);
      });
  }

  async addImage(file, callback) {
    let uid = this.getUid();
    let result = undefined;
    if (file !== undefined) {
      var mountainImagesRef = storageRef.child("images/" + file.name);
      await mountainImagesRef
        .put(file)
        .then(async function (snapshot) {
          await snapshot.ref
            .getDownloadURL()
            .then(async function (downloadURL) {
              result = downloadURL;
            })
            .catch((error) => {
              throw new Error(error);
            });
          callback(true, "Image Add Success", result);
        })
        .catch((error) => {
          callback(false, "Image Add Failed");
          // throw new Error(error);
        });
      return result;
    }
  }

  async updateImage(file, fileName, callback) {
    let uid = this.getUid();
    let result = undefined;

    if (file !== undefined) {
      var mountainImagesRef = storageRef.child("images/" + fileName);
      await mountainImagesRef
        .put(file)
        .then(async function (snapshot) {
          await snapshot.ref
            .getDownloadURL()
            .then(async function (downloadURL) {
              result = downloadURL;
            })
            .catch((error) => {
              throw new Error(error);
            });
          callback(true, "Image Add Success", result);
        })
        .catch((error) => {
          callback(false, "Image Add Failed");
          // throw new Error(error);
        });
      return result;
    }
  }

  async deleteImage(fileName, callback) {
    let uid = this.getUid();
    let desertRef = await storageRef.child(`images/${fileName}`);

    await desertRef
      .delete()
      .then(function () {
        callback(true, "Image Delete Success");
      })
      .catch(function (error) {
        callback(false, "Image Delete Failed");
      });
  }

  async addDataWithImage(
    collection_val,
    payloadIn,
    callback,
    documentId = undefined
  ) {
    let uid = this.getUid();
    let payload = { ...payloadIn };
    if (payload.file !== undefined) {
      let imageFIleName = payload.file.name;
      await this.addImage(payload.file, async (type, msg, result) => {
        if (type) {
          delete payload.file;
          await this.addData(
            collection_val,
            {
              ...payload,
              imageUrl: result,
              imageFileName: imageFIleName,
              uid,
            },
            (type, msg) => {
              callback(type, msg);
            }
          );
        } else {
          callback(false, "Data Add Failed");
        }
      });
    } else {
      delete payload.file;
      await this.addData(
        collection_val,
        {
          ...payload,
          uid,
        },
        (type, msg) => {
          callback(type, msg);
        }
      );
    }
  }

  async updateDataWithImage(collection_val, payloadIn, documentId, callback) {
    let uid = this.getUid();
    let payload = { ...payloadIn };
    if (payload.file !== undefined) {
      let imageFileName =
        payload.imageFileName === undefined
          ? payload.file.name
          : payload.imageFileName;
      await this.updateImage(
        payload.file,
        imageFileName,
        async (type, msg, result) => {
          if (type) {
            delete payload.file;
            await this.updateData(
              collection_val,
              {
                ...payload,
                imageUrl: result,
                imageFileName,
                uid,
              },
              documentId,
              async (type, msg) => {
                callback(type, msg);
              }
            );
          } else {
            callback(false, "Data Update Failed");
          }
        }
      );
    } else {
      delete payload.file;
      await this.updateData(
        collection_val,
        {
          ...payload,
          uid,
        },
        documentId,
        async (type, msg) => {
          callback(type, msg);
        }
      );
    }
  }

  async deleteDataWithImage(
    collection_val,
    documentId,
    imageFileName,
    callback
  ) {
    let uid = this.getUid();
    if (imageFileName !== undefined) {
      await this.deleteImage(imageFileName, async (type, msg) => {
        if (type) {
          await this.deleteData(collection_val, documentId, (type, msg) => {
            type ? callback(type, msg) : callback(false, "Data delete failed");
          });
        } else {
          callback(false, "Data delete failed");
        }
      });
    } else {
      await this.deleteData(collection_val, documentId, (type, msg) => {
        type ? callback(type, msg) : callback(false, "Data delete failed");
      });
    }
  }
}
const DataService = new CDataService();
export default DataService;
