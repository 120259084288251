import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AlertMessage from "./component/AlertMessage";
import Front from "./component/Front";
import firebase from "./util/firebase";
import { setContactsSnapshot } from "./util/FirebaseSnapshot";

function App() {

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setContactsSnapshot();
      } else {
        // No user is signed in.
        window.alertMsg("Not Signed in", "error");
      }
    });
  }, []);

  return (
    <div>
      <Front />
      <AlertMessage />
    </div>
  );
}
export default App;
