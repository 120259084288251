// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDRkVW_PXu8jEsytwaUyGKvTXnRadJdJ8I",
//   authDomain: "speed-sell-test.firebaseapp.com",
//   projectId: "speed-sell-test",
//   storageBucket: "speed-sell-test.appspot.com",
//   messagingSenderId: "819222192693",
//   appId: "1:819222192693:web:7eef8c342dbc9b3197077c"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDna53LlFT8YjV1Uswn7ZUs4xC13YjrANw",
  authDomain: "school-project-manager.firebaseapp.com",
  projectId: "school-project-manager",
  storageBucket: "school-project-manager.appspot.com",
  messagingSenderId: "775795894225",
  appId: "1:775795894225:web:151d857a1a1e79660422f0",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
// Subsequent queries will use persistence, if it was enabled successfully

export default firebase;
