import ChangePassword from "../component/account/ChangePassword";
import ForgetPassword from "../component/account/ForgetPassword";
import SignIn from "../component/account/SignIn";
import SignUp from "../component/account/SignUp";
import UpdateProfile from "../component/account/UpdateProfile";
import AdminDashboard from "../component/dashboard/AdminDashboard";
import DashboardHome from "../component/dashboard/home/DashboardHome";

export const routes = [

  { path: "update-profile", component: UpdateProfile, exact: true },
  { path: "change-password", component: ChangePassword, exact: true },

  { path: "/", component: DashboardHome, exact: true },
];

export const frontRoutes = [
  { path: "/", component: SignIn, exact: true },
  { path: "/signup", component: SignUp, exact: false },
  { path: "/forget-password", component: ForgetPassword, exact: false },
  { path: "/dashboard-admin", component: AdminDashboard, exact: false },
];
