import cartReducer from "./cartReducer";
import productReducer from "./productReducer";
import deviceReducer from "./deviceReducer";
import alert from "./alert";
import categoryReducer from "./categoryReducer";
import contactReducer from "./contactReducer";

import posFrontReducer from "./posFrontReducers";
import order from "./order";
import customer from "./customer";
import currency from "./currency";
import settings from "./settings";
import unit from "./unit";
import supplier from "./supplier";
import store from "./store";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  alert,
  contactReducer,
});

export default rootReducer;
