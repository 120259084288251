import { CircularProgress } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "../../util/firebase";
import Copyright from "../Copyright";
import { isElectron } from "../../util/functions";
import Loading from "../Loading";
import { deepOrange } from "@material-ui/core/colors";
import { Image } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 70,
    height: 70,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const history = useHistory();

  const [form, setState] = useState({
    isLoaded: false,
    isLoading: true,
    email: "",
    password: "",
    access_token: "",
    rememberUser: true,
  });

  const handleUpdate = (event) => {
    setState({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmailLogIn = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(form.email, form.password)
      .then((result) => {
        window.alertMsg("Login Success");
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;

        localStorage.setItem("uid", user.uid);
        localStorage.setItem("user", JSON.stringify(user));

        isElectron() ? history.push("/pos") : history.push("/dashboard-admin");
        // ...
      })
      .catch((error) => {
        console.log(error);
        window.alertMsg("Login Failed", "error");
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
  };

  const handleLoginRedirect = (e) => {};

  useEffect(() => {
    localStorage.setItem("rememberUser", form.rememberUser);

    if (
      localStorage.getItem("token") !== null &&
      localStorage.rememberUser === "true"
    ) {
      handleLoginRedirect(null);
    } else {
      setState({
        ...form,
        isLoaded: true,
      });
    }
  }, []);

  if (!form.isLoaded) {
    return <Loading value="Application" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <img className={classes.avatar} src="/image/svg/logo.svg" alt="logo" />

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleEmailLogIn}>
          {/* <Grid container style={{marginBottom:15}}>
        <Grid item xs={6}>
          
          <GoogleLoginButton text="Google" onClick={handleLogin} />
            
        </Grid>
        <Grid item xs={6}>
          
          <FacebookLoginButton text="Facebook" onClick={() => alert("Facebook Login")} />
            
        </Grid>
        <Grid item xs={12}>
            
        </Grid> 
      </Grid> 
      <DividerWithText>or</DividerWithText> */}
          <TextField
            variant="outlined"
            margin="normal"
            // required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={form.email}
            onChange={handleUpdate}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            // required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={form.password}
            onChange={handleUpdate}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={form.rememberUser}
                onChange={(event) => {
                  setState({
                    ...form,
                    rememberUser: event.target.checked,
                  });
                  localStorage.setItem("rememberUser", event.target.checked);
                }}
              />
            }
            label="Remember me"
          />
          <Button
            startIcon={<VpnKeyIcon />}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            <CircularProgress className={classes.circularProgress} size={20} />
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" to="/forget-password">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link variant="body2" to="/signup">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
