import { store } from "..";
import actions from "../redux/actions";
import DataService from "./DataService";

export const setContactsSnapshot = () => {
  let data_val = [];
  DataService.setSnapshotOpenSecurity("contact", (querySnapshots) => {
    querySnapshots.forEach(function (doc) {
      data_val.push({ id: doc.id, ...doc.data() });
    });
    store.dispatch(actions.contactActions.addContact(data_val));
    data_val = [];
  });
};
