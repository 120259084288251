import alert from "./alert";

import contactActions from "./contactActions";

const actions = {
  alert,
  contactActions,
};

export default actions;
