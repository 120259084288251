//Alert
export const OPEN_ALERT = "OPEN_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";

//Contact
export const ADD_CONTACT = "ADD_CONTACT";

//Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const MODIFY_CART = "MODIFY_CART";
export const SELECT_CART = "SELECT_CART";

//Category
export const ADD_CATEGORY = "ADD_CATEGORY";

//Tax
export const ADD_TAX = "ADD_TAX";

//Unit
export const ADD_UNIT_LIST = "ADD_UNIT_LIST";

//paymentMethod
export const ADD_PAYMENT_METHOD_LIST = "ADD_PAYMENT_METHOD_LIST";

//Supplier
export const ADD_SUPPLIER_LIST = "ADD_SUPPLIER_LIST";

//Currency
export const ADD_CURRENCY_LIST = "ADD_CURRENCY_LIST";

//settings
export const ADD_SETTINGS = "ADD_SETTINGS";

//Product
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_FILTERED_PRODUCT = "UPDATE_FILTERED_PRODUCT";
export const MODIFY_PRODUCT = "MODIFY_PRODUCT";
export const INCREASE_PRODUCT_STOCK_CART = "INCREASE_PRODUCT_STOCK_CART";
export const DECREASE_PRODUCT_STOCK_CART = "DECREASE_PRODUCT_STOCK_CART";
export const MODIFY_PRODUCT_STOCK_CART = "MODIFY_PRODUCT_STOCK_CART";

//Device
export const ADD_DEVICE = "ADD_DEVICE";
export const ADD_STORE = "ADD_STORE";
export const REMOVE_DEVICE = "REMOVE_DEVICE";
export const MODIFY_DEVICE = "MODIFY_DEVICE";
export const SELECT_DEVICE = "SELECT_DEVICE";

//Store
export const ADD_STORE_LIST = "ADD_STORE_LIST";

//POS Front
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";

//Orders
export const ADD_ORDERS = "ADD_ORDERS";

//Customers
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
