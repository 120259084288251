import { MenuItem, Popover } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import actions from "../../redux/actions";
import DataService from "../../util/DataService";
import firebase from "../../util/firebase";
import { handleLogout } from "../../util/functions";
import { routes } from "../../util/routes";
import MainListItems from "./AdminlistItems";
import DashboardHome from "./home/DashboardHome";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
    // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    // ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    margin: 0,
    padding: 0,
  },
  container: {
    maxWidth: "100%",
    paddingTop: theme.spacing(1),
    margin: 0,
    paddingBottom: theme.spacing(1),
  },
  paper: {
    // padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function AdminDashboard() {
  const classes = useStyles();
  const history = useHistory();
  let { path, url } = useRouteMatch();
  // const product = useSelector(state => state.productReducer)
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAnchor = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        window.alertMsg("Authenticated")
      } else {
        window.alertMsg("Not Authenticated", "error")
        handleLogout()
        // No user is signed in.
      }
    });
  }, []);

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar} variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <div>
              
            </div>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
                                <IconButton
                    onClick={handleClick}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <AccountCircleIcon />
                   
                  </IconButton>
                  {JSON.parse(localStorage.getItem("user")).displayName}

            </Typography>
            
            {
              <div>
                <div >
                  

                
                </div>

                
                <Popover
                  id={id}
                  open={openAnchor}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    {"User Name: " +
                      JSON.parse(localStorage.getItem("user")).displayName}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {"Email: " + JSON.parse(localStorage.getItem("user")).email}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    Log Out
                  </MenuItem>
                </Popover>
                
              </div>
            }
          </Toolbar>
        </AppBar>
        <Drawer
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <Box display="flex" justifyContent="center">
              Dashboard
            </Box>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <MainListItems />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
            <Route exact path={path}>
              <DashboardHome />
            </Route>
              {routes.map((route, index) => (
                <Route exact={route.exact} path={`${path}/:${route.path}`} key={index}>
                  <route.component />
                </Route>
              ))}
            </Switch>
          </Container>
        </main>
      </div>
    </Router>
  );
}
