import firebase from "./firebase";

export const handleLogout = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      window.location.href = "/";
    })
    .catch(function (error) {
      // An error happened.
    });
};

export function isElectron() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }

  return false;
}

export const modifyStock = (stock) => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      window.location.href = "/";
    })
    .catch(function (error) {
      // An error happened.
    });
};

export const calculateStock = (stockDetails) => {
  let stock = 0;
  stockDetails.forEach((element) => {
    stock += parseFloat(element.data().quantity);
  });
  return stock;
};

export const calculateAverageCost = (stockDetails) => {
  let stock = 0;
  let totalCost = 0;
  stockDetails.forEach((element) => {
    stock += parseFloat(element.data().quantity);
    totalCost += parseFloat(element.data().cost) * stock;
  });
  return totalCost / stock;
};

export const getStockCostQty = (stock, qty) => {
  let qtyCount = 0;
  let costItem = [];
  stock.some((element, index) => {
    let oldQtyCount = qtyCount;
    qtyCount += parseFloat(element.newStock);
    if (index === 0) {
      if (qtyCount >= qty) {
        costItem.push({ cost: element.cost, qty: qty });
        return true;
      } else {
        costItem.push({
          cost: element.cost,
          qty: parseFloat(element.newStock),
        });
      }
    } else {
      if (qtyCount >= qty) {
        costItem.push({ cost: element.cost, qty: qty - oldQtyCount });
        return true;
      } else {
        costItem.push({
          cost: element.cost,
          qty: parseFloat(element.newStock),
        });
      }
    }
  });
  return costItem;
};

export const getUpdatedStockDetails = (stock, qty) => {
  let qtyCount = 0;
  let remainingQty = qty;
  let stockDetails = [];
  stock.forEach((element, index) => {
    if (index === 0) {
      qtyCount += parseFloat(element.newStock);
      if (qtyCount >= qty) {
        stockDetails.push({ cost: element.cost, newStock: qtyCount - qty });
        remainingQty = 0;
      } else {
        stockDetails.push({ cost: element.cost, newStock: 0 });
        remainingQty -= parseFloat(element.newStock);
      }
    } else {
      if (remainingQty >= parseFloat(element.newStock)) {
        stockDetails.push({ cost: element.cost, qty: 0 });
        remainingQty -= parseFloat(element.newStock);
      } else {
        stockDetails.push({
          cost: element.cost,
          newStock: parseFloat(element.newStock) - remainingQty,
        });
      }
    }
  });
  return stockDetails;
};
