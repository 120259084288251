import { createTheme, ThemeProvider } from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { frontRoutes } from "../util/routes";
import Loading from "./Loading";

const theme = createTheme({
  palette: {
    // primary: blue,
    secondary: pink,
    primary: {
      main: blue[500],
      lighter: blue[100],
    },
  },
});

export default function Front() {



  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading value="Application" />}>
        <Router>
          <Switch>
            {frontRoutes.map((route, index) => (
              <Route exact={route.exact} path={`${route.path}`} key={index}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}
