import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import { config } from "../../util/config";
import firebase from "../../util/firebase";
import Copyright from "../Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();

  const [state, setState] = useState({
    isLoaded: false,
    current_password: null,
    new_password: null,
    re_enter_new_password: null,
  });

  const handleUpdate = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (config.isDemo) {
      window.alertMsg("you can't update this in demo mode", "error");
      return;
    }
    if (state.new_password !== state.re_enter_new_password) {
      window.alertMsg("mismatch new entered password !", "error");
      return;
    }

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .updatePassword(state.new_password)
          .then(function () {
            // Update successful.
            window.alertMsg("Password Updated");
          })
          .catch(function (error) {
            // An error happened.
            window.alertMsg("Failed", "error");
          });
      } else {
        // No user is signed in.
        window.alertMsg("Not Signed in", "error");
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="new_password"
            value={state.new_password}
            label="New Password"
            type="password"
            id="new_password"
            autoComplete="new_password"
            onChange={handleUpdate}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="re_enter_new_password"
            value={state.re_enter_new_password}
            label="Re Enter New Password"
            type="password"
            id="re_enter_new_password"
            autoComplete="re_enter_new_password"
            onChange={handleUpdate}
          />
          <Button
            startIcon={<LockOutlinedIcon />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleUpdatePassword}
          >
            Update
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
