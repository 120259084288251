import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Copyright from "../Copyright";
import firebase from "../../util/firebase";
import { isElectron } from "../../util/functions";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 70,
    height: 70,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();

  const [form, setState] = useState({
    isLoaded: false,
    payload: {},
  });

  const handleUpdate = (event) => {
    setState({
      ...form,
      payload: {
        ...form.payload,
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(form.payload.email, form.payload.password)
      .catch(function (error) {
        console.log(error);
        window.alertMsg("Something went wrong", "error");
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .updateProfile({
            displayName: form.full_name,
            phoneNumber: form.mobile,
            // photoURL: "https://example.com/jane-q-user/profile.jpg"
          })
          .then(function () {
            window.alertMsg("account has been created");
            console.log("Update successful");
            localStorage.setItem("uid", user.uid);
            localStorage.setItem("user", JSON.stringify(user));
            isElectron()
              ? history.push("/pos")
              : history.push("/dashboard-admin");
            // Update successful.
          })
          .catch(function (error) {
            window.alertMsg("Something went wrong", "error");
            console.log("Error");
            // An error happened.
          });
      } else {
        window.alertMsg("Not Logged In", "error");
        console.log("Sign Out");
        // User is signed out.
        // ...
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <img className={classes.avatar} src="/image/svg/logo.svg" alt="logo" />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={handleSignUp}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="full_name"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Full Name"
                autoFocus
                onChange={handleUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="mobile"
                label="Mobile No"
                id="mobile"
                autoComplete="mobile"
                onChange={handleUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleUpdate}
              />
            </Grid>
          </Grid>
          <Button
            startIcon={<AccountBoxOutlinedIcon />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
        </form>
        <Grid container justify="flex-end">
          <Grid item>
            <Link href="#" variant="body2" to="/">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
