import { TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function TopSalesProduct() {
  const classes = useStyles();
  const contact = useSelector(state => state.contactReducer)
  const [state, setState] = useState({ searchText: '' })
  const [hasMore, setHasMore] = useState(true)
  const [filteredContact, setFilteredContact] = useState({ data: [] });
  const [originalContact, setOriginalContact] = useState({ data: [] });


  const filterDataMethod = (searchTextVal) => {
    let filteredData = []
    let theArray = originalContact.data;
    let searchTextValue = searchTextVal.toLowerCase();
    if (searchTextValue.length !== 0) {
      let resultCount = 0;
      for (let i = 0, l = theArray.length; i < l; ++i) {
        if (resultCount === 31) {
          break;
        } else if (theArray[i].searchTerm.includes(searchTextValue)) {
          filteredData.push(theArray[i]);
          resultCount++;
        }

      }
      setFilteredContact({ data: filteredData });
    } else {
      setFilteredContact({ data: originalContact.data.slice(0, 30) });
    }
  }

  const fetchMoreData = () => {
    let payload = [...filteredContact.data, ...originalContact.data.slice(filteredContact.data.length, 20)];
    console.log(payload);
    setFilteredContact({ data: payload });
  }

  useEffect(() => {
    let modContact = contact.items.map(item => {
      return { ...item, searchTerm: item['School Name'].toLowerCase() }
    }
    );
    setFilteredContact({ data: modContact.slice(0, 20) });
    setOriginalContact({ data: modContact });
  }, [contact])

  return (
    <React.Fragment>

      <TextField

        label="Search input"
        margin="normal"
        variant="outlined"
        // InputProps={{ type: 'search' }}
        onChange={e => filterDataMethod(e.target.value)}
      />
      <Scrollbars style={{ height: "70vh" }}>
        <List className={classes.root}>

          {filteredContact.data.map((item, index) => (
            <Fragment key={index}>
              <Divider variant="fullWidth" component="li" />
              <ListItem alignItems="flex-start">
                {/* <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar> */}
                <ListItemText
                  // primary={item['School Name']}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        <h4 style={{margin:0, marginBottom:4}}>{item['School Name']}</h4>
                        <div>
                          ({item['Circle Name']})<br/>
                        </div>
                        <div>
                          HoS/In-Charge:
                          {' '+item['HoS/In-Charge Name']}<br/>
                          ({item['HoS/In-Charge Type']})<br/>
                          <a href={`tel:+${item['HoS/In-Charge Mobile No']}`}> ✆ {item['HoS/In-Charge Mobile No']}</a>
                        </div>
                        <div>
                          Respondent:
                          {' '+item['Respondent Name']}<br/>
                          ({item['Respondent Type']})<br/>
                          <a href={`tel:+${item['Respondent Mobile No']}`}> ✆ {item['Respondent Mobile No']}</a>
                        </div>
                        <div>
                          School Mobile No:<br/>
                          <a href={`tel:+${item['School Mobile No']}`}> ✆ {item['School Mobile No']}</a>
                        </div>

                      </Typography>
                      {`${item.id}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Fragment>

          ))}
        </List>
      </Scrollbars>


    </React.Fragment>
  );
}
