import { ADD_CONTACT } from "./actionTypes";

const addContact = (payload) => {
  return {
    type: ADD_CONTACT,
    payload: payload,
  };
};

export default {
  addContact,
};
