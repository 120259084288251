import { ADD_CONTACT } from "../actions/actionTypes";

const initialState = {
  items: [],
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACT:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};

export default contactReducer;
